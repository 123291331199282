import styled from 'styled-components'
import { MarkedHeader } from '@common/MarkedHeader'

export const MainWrapper = styled.div`
  margin: 30px 0 50px 0;
  ${props => props.theme.flex.column}
`
export const WelcomeWrapper = styled(MarkedHeader)`
  font-size: ${props => props.theme.fontSizes.size20};
  background-color: ${props => props.theme.color.aqua};
  box-shadow: 3px 0 0 ${props => props.theme.color.aqua},
    -3px 0 0 ${props => props.theme.color.aqua};
  margin: 10px 0 30px 0;
  width: fit-content;
`

export const ParagraphWrapper = styled.p`
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSizes.size16};
  word-break: break-word;
  padding-left: 15px;
`

export const UnderlinedParagraphWrapper = styled(ParagraphWrapper)`
  text-decoration: underline;
`

export const SubHeaderWrapper = styled.h3`
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSizes.size20};
  font-weight: ${props => props.theme.fontWeight.bold};
`

export const ListWrapper = styled.ul`
  margin-left: 40px;
`

export const WarningWrapper = styled(WelcomeWrapper)`
  font-size: ${props => props.theme.fontSizes.size16};
  width: fit-content;
  padding: 0;
  margin: 15px;
`

export const LogoWrapper = styled.div`
  margin: 20px 0 40px 0;
`
export const BackToHomeLinkWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover {
    text-decoration: underline;
  }
`
