/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import BibleLayout from '@layouts/BibleLayout'
import {
  MainWrapper,
  LogoWrapper,
  BackToHomeLinkWrapper,
} from '@common/CommonDisclamerPagesStyles'
import Logo from '@common/Logo'

const DisclamerPagesCommonContainer = ({ location, children }) => {
  const [externalBackLink, setExternalLink] = useState('')
  const [logoPathPrefix, setlogoPathPrefix] = useState('../')
  useEffect(() => {
    if (document) setExternalLink(document.referrer)
    if (window.location) {
      if (
        window.location.pathname.includes('/de/') ||
        window.location.pathname.includes('/en/')
      ) {
        setlogoPathPrefix('../../')
      }
    }
  }, [])

  const backLinkText = 'Back to Home Page'

  return (
    <BibleLayout showTaxesNote={false} location={location}>
      <MainWrapper>
        <LogoWrapper>
          <Logo
            imgSrc={`${logoPathPrefix}MainLogo@2x.png`}
            height={30}
            width={200}
          />
          <BackToHomeLinkWrapper>
            {externalBackLink ? (
              <a href={externalBackLink}>{backLinkText}</a>
            ) : (
              <Link
                to={location.state ? location.state.homepage : '/'}
                state={
                  location.state ? { ...location.state } : { homepage: '/' }
                }
              >
                {backLinkText}
              </Link>
            )}
          </BackToHomeLinkWrapper>
        </LogoWrapper>
        {children}
      </MainWrapper>
    </BibleLayout>
  )
}

DisclamerPagesCommonContainer.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default DisclamerPagesCommonContainer
